import React from "react"
import { graphql } from "gatsby"
import { localize } from "../lib/helpers"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Page from "../components/Work/Page"
import Cta from "../components/cta"

const WorkPage = ({ data, pageContext, location }) => {
  const
    { _rawTitle, _rawQuote, _rawCta } = localize(data.sanityWork, [pageContext.language]),
    projects = localize(data.projects.nodes, [pageContext.language]);

  return (
    <Layout lang={pageContext.language}>
      <Seo title={_rawTitle} lang={pageContext.language} location={location}/>
      <div id="work">
        <section data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="work--quote">
          <h1 className="TT60-thin">{_rawQuote.quote}</h1>
          <p className="M16-regular">{_rawQuote.author}</p>
        </section>

        <Page projects={projects.slice(0, 12)} />

        {projects.length > 12 &&
          <Page projects={projects.slice(12, 24)} />
        }

        {projects.length > 24 &&
          <Page projects={projects.slice(24, 36)} />
        }

        <section data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className="work--pagination">
          <Cta cta={_rawCta} />
        </section>
      </div>

    </Layout>
  )
}

export const query = graphql`
  query WorkPage {
    sanityWork {
      _rawTitle
      _rawQuote
      _rawCta
    }
    projects: allSanityProject(sort: {fields: _createdAt, order: DESC}) {
      nodes {
        _id
        _rawSlug
        _rawLiner
        title
        category
        covers {
          asset {
            gatsbyImageData(width:980, layout: CONSTRAINED)
          }
        }
      }
    }
  }
`

export default WorkPage
