import React from "react"
import { Link } from "gatsby"
import { useTranslation } from "react-i18next"

import Image from "./image"

const Project = ({ project: {title, covers, _rawLiner, category, _rawSlug, slug }, index }) => {
  const { i18n } = useTranslation('common')

  return(
    <div data-sal='fade' data-sal-duration='750' data-sal-easing='ease' className={`project-container p-${index}`}>
      <Link to={`/${i18n.language}/${_rawSlug?.current || slug.current}`} className="covers">
        {covers?.map((cover, i) => (
          <Image key={`image-proj-${i*6}`} className={`border c-${i}`} image={cover.asset} alt={`${title} | Latente Studio`}/>
        ))
        }
      </Link>

      <div className="info">
        <div className="left-container">
          <h6>{title}</h6>
          <p>{_rawLiner}</p>
        </div>

        <ul>
          {category?.map((cat, i) => (
            <li key={`li-proj-${i*3}`}>{cat}</li>
          ))}
        </ul>
      </div>

    </div>
  )
}

export default Project
