import React from "react"
import Project from "../project"

const Page = ({ projects }) => {
  const
    row1 = projects?.slice(0, 2),
    row2 = projects?.slice(2, 5),
    row3 = projects?.slice(5, 7),
    row4 = projects?.slice(7, 9),
    row5 = projects?.slice(9, 12);

  return (
    <section className="grid-20 work--projects">
      {row1.map((project, i)=> (
        <Project key={project._id} project={project} index={i}/>
      ))}

      <div className="full-row-three">
        {row2.map((project, i)=> (
          <Project key={project._id} project={project} index={i + 2}/>
        ))}
      </div>

      {row3.map((project, i)=> (
        <Project key={project._id} project={project} index={i + 5}/>
      ))}

      {row4.map((project, i)=> (
        <Project key={project._id} project={project} index={i + 7}/>
      ))}

      <div className="full-row-three">
        {row5.map((project, i)=> (
          <Project key={project._id} project={project} index={i + 9}/>
        ))}
      </div>
    </section>
  )
}

export default Page
